import { yupResolver } from '@hookform/resolvers/yup'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import ApplicantParameters from '@src/containers/CreditApplicationPage/components/Applicant/ApplicantComponent'
import PageSpinner from '@src/components/PageSpinner'
import { useCreditApplicationById, usePostCoapplicant } from '@src/data/api/credit-api/credit-api'
import { reportErrorToConsole } from '@src/services/error-logger'
import { ApplicantDto, FullApplicantSchema } from '@src/types/CreditApplicationSchema'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { EFinancingProgram } from '@src/types/Constants'
import { PostCoapplicantParams } from '@src/data/api/type'

const AddCoapplicantPage = () => {
  const { id, financingProgramId } = useParams<{ id: string; financingProgramId: EFinancingProgram }>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [addCoapplicant, isPending, isError] = usePostCoapplicant()
  const [creditApplication, isLoadingCreditApp] = useCreditApplicationById(
    { creditApplicationId: id!, financingProgramId: financingProgramId! },
    false,
  ) // if true, poll every 5 secs

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<ApplicantDto>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: FullApplicantSchema.getDefault(),
    resolver: yupResolver(FullApplicantSchema),
  })

  const navigateToView = () => {
    navigate(`/credits/${financingProgramId}/${id}`)
  }

  const onSave = async (data: Partial<ApplicantDto>) => {
    const postParam: PostCoapplicantParams = {
      creditApplicationParams: { creditApplicationId: id!, financingProgramId: financingProgramId! },
      coApplicant: data as ApplicantDto,
    }

    await addCoapplicant(postParam).then(navigateToView)
  }

  return (
    <div style={{ height: '100%' }}>
      <PageSpinner isLoading={isPending || isLoadingCreditApp} withBackdrop />

      {creditApplication && (
        <Card
          component="form"
          onSubmit={handleSubmit(onSave, reportErrorToConsole)}
          title={t('viewCreditApplication.coApplicant') as string}
        >
          <CardContent>
            <Typography
              variant="h3"
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              {t('viewCreditApplication.coApplicant')}
            </Typography>

            <ApplicantParameters
              financingProgramId={financingProgramId!}
              register={register}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
              errors={errors}
              formControl={control}
              editDisabled={false}
              prohibitedPhone={creditApplication?.applicant.cellPhone ?? ''}
            />

            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <AsyncActionButton color="error" onClick={navigateToView}>
                  {t('common.cancel')}
                </AsyncActionButton>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AsyncActionButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  isPending={isPending}
                  isError={isError}
                >
                  {t('common.save')}
                </AsyncActionButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
export default AddCoapplicantPage
