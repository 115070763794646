import {
  Constants,
  defaultProgramConfig,
  ECompagny,
  EDocType,
  EDocumentStatus,
  EFinancingProgram,
  EOriginSystemId,
  EProvince,
  FinancingCompanyConfigs,
  HiddenMerchantCategories,
  MinimumAgeRequirementByStateIso,
} from '@src/types/Constants'
import type { CreditApplication } from '@src/types/CreditApplicationSchema'
import { RequiredDocument } from '@src/types/RequiredDocument'

export const programConfigByFinancingProgramId = (financingProgram: EFinancingProgram) => {
  return Constants.financingProgramConfigs[financingProgram] ?? defaultProgramConfig
}

export const companyConfigByFinancingProgramId = (financingProgram: EFinancingProgram) => {
  const icebergConfig = FinancingCompanyConfigs[ECompagny.Iceberg]
  const ifinanceConfig = FinancingCompanyConfigs[ECompagny.IFinance]

  if (icebergConfig.supportedPrograms.includes(financingProgram)) {
    return icebergConfig
  }
  return ifinanceConfig
}

export const anyDocsAwaitingApproval = (requiredDocuments: RequiredDocument[]) => {
  return requiredDocuments?.some((doc) => doc.status === EDocumentStatus.AwaitingApproval)
}

export const anyDocsAwaitingUploadOrRefused = (requiredDocuments: RequiredDocument[]) => {
  return requiredDocuments?.some(
    (doc) =>
      doc.status === EDocumentStatus.AwaitingDocument ||
      doc.status === EDocumentStatus.Incomplete ||
      doc.status === EDocumentStatus.Refused ||
      doc.status === EDocumentStatus.Deleted,
  )
}

export const getCanEdit = (originSystemId: EOriginSystemId, isEditLocked: boolean) => {
  return originSystemId === EOriginSystemId.MerchantDashboard && !isEditLocked
}

export const getTimeStamp = () => {
  const now = new Date()

  const year = now.getUTCFullYear().toString()
  const month = (now.getUTCMonth() + 1).toString()
  const day = now.getUTCDate().toString()
  const hour = now.getUTCHours().toString()
  const minute = now.getUTCMinutes().toString()
  const second = now.getUTCSeconds().toString()

  return `${year}${month}${day}${hour}${minute}${second}`
}

export const CvtAwaitingApproval = (requiredDocuments: RequiredDocument[]) => {
  return requiredDocuments?.some(
    (doc) => doc.typeId === EDocType.signedCvt && doc.status === EDocumentStatus.AwaitingApproval,
  )
}

export const shouldDisplayInterest = (creditApp: CreditApplication) => {
  if (creditApp.originSystemId !== EOriginSystemId.MerchantDashboard) return false
  return !HiddenMerchantCategories.includes(creditApp.loanPurposeId)
}

export function findMinimumAgeRequirement(stateIso: EProvince) {
  switch (stateIso) {
    case EProvince.quebec:
      return MinimumAgeRequirementByStateIso.quebec
    case EProvince.ontario:
      return MinimumAgeRequirementByStateIso.ontario
    case EProvince.novaScotia:
      return MinimumAgeRequirementByStateIso.novaScotia
    case EProvince.newBrunswick:
      return MinimumAgeRequirementByStateIso.newBrunswick
    case EProvince.princeEdwardIsland:
      return MinimumAgeRequirementByStateIso.princeEdwardIsland
    case EProvince.newfoundland:
      return MinimumAgeRequirementByStateIso.newfoundland
    case EProvince.britishColombia:
      return MinimumAgeRequirementByStateIso.britishColombia
    case EProvince.saskatchewan:
      return MinimumAgeRequirementByStateIso.saskatchewan
    case EProvince.alberta:
      return MinimumAgeRequirementByStateIso.alberta
    case EProvince.manitoba:
      return MinimumAgeRequirementByStateIso.manitoba
    case EProvince.yukon:
      return MinimumAgeRequirementByStateIso.yukon
    case EProvince.nunavut:
      return MinimumAgeRequirementByStateIso.nunavut
    case EProvince.northwestTerritories:
      return MinimumAgeRequirementByStateIso.northwestTerritories
    default:
      return 18
  }
}

export const availableCreditEqualApprovedAmount = (creditApp: CreditApplication) => {
  return creditApp.availableCreditAmount === creditApp.finalCreditDecision.maxAmountFinanced
}
