import { DraftCreditApplicationDto } from '@src/types/CreditApplicationSchema'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { useUser } from '@src/contexts/AppContext'
import { useCreditApplicationById, useEditCreditApplication } from '@src/data/api/credit-api/credit-api'
import { useNavigate, useParams } from 'react-router-dom'
import { EBeneficiaryType, EFinancingProgram } from '@src/types/Constants'
import CreditApplicationForm from './components/CreditApplicationForm'

const EditCreditApplicationPage = (): JSX.Element => {
  const user = useUser()
  const { financingProgramId, id } = useParams<{ financingProgramId: EFinancingProgram; id: string }>()
  const [merchant] = useMerchantById(user?.merchantId ?? '')
  const navigate = useNavigate()

  const [creditApplication, isLoadingCreditApp] = useCreditApplicationById(
    {
      creditApplicationId: id!,
      financingProgramId: financingProgramId!,
    },
    false,
  ) // if true, poll every 5 secs

  const [editCreditAppMutation, isPending, isError, reset, error] = useEditCreditApplication()
  const onSave = async (data: Partial<DraftCreditApplicationDto>) => {
    data.id = id
    if (!data.beneficiaryTypeId) data.beneficiaryTypeId = EBeneficiaryType.Applicant
    await editCreditAppMutation(data).then(() => {
      reset()
      navigate('/')
    })
  }

  return (
    <div>
      {!isLoadingCreditApp && creditApplication && (
        <CreditApplicationForm
          merchant={merchant}
          creditApplication={creditApplication}
          onSave={onSave}
          isPending={isPending}
          isError={isError}
          serverErrors={error}
          financingProgramId={financingProgramId!}
        />
      )}
    </div>
  )
}

export default EditCreditApplicationPage
