import React from 'react'
import { Box, IconButton, Stack } from '@mui/material'
import { Control, FieldError, FieldErrorsImpl, Merge, UseFormRegister, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import { ApplicantOtherIncome } from '@src/types/CreditApplicationSchema'
import SelectComponent from '@src/components/SelectComponent'
import InputTextField from '@src/components/InputTextField'
import { EOtherIncomeType, EOtherIncomeTypeList } from '@src/types/Constants'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any>
  errors: Merge<FieldError, FieldErrorsImpl<ApplicantOtherIncome>> | undefined
  name: string
  onDeleteClicked: () => void
  disableEdit: boolean
}

const OtherIncomeComponent = ({ register, errors, name, onDeleteClicked, formControl, disableEdit }: Props) => {
  const { t } = useTranslation()
  const typeId: number = +useWatch({
    control: formControl,
    name: `${name}.typeId`,
  })

  return (
    <Stack direction="row" spacing={1} alignItems="start">
      <SelectComponent
        valueList={EOtherIncomeTypeList.filter(
          (incomeType) => incomeType !== EOtherIncomeType.incomeServiceAdjustment,
        ).map((value) => ({ label: t(`otherIncomeTypes.${value}`), value }))}
        label={t('editCreditApplication.income.other.typeId') as string}
        {...register(`${name}.typeId`)}
        error={errors?.typeId}
        disabled={onDeleteClicked === undefined}
      />
      {typeId === 6 && (
        <InputTextField
          id="otherIncomeDescription"
          error={errors?.description}
          label="Description"
          {...register(`${name}.description`)}
          disabled={onDeleteClicked === undefined}
          inputProps={{ maxLength: 50 }}
        />
      )}
      <InputTextField
        id="annualAmount"
        type="number"
        error={errors?.annualAmount}
        label={t('editCreditApplication.income.other.annualAmount')}
        {...register(`${name}.annualAmount`)}
        disabled={onDeleteClicked === undefined}
      />
      <Box>
        <IconButton
          id="otherIncomeDelete"
          aria-label="delete"
          color="error"
          sx={{ mt: 1 }}
          onClick={onDeleteClicked}
          disabled={disableEdit}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Stack>
  )
}

export default OtherIncomeComponent
