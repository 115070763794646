import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { CircularProgress, Typography } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useQualify } from '@src/data/api/credit-api/credit-api'
import { useState } from 'react'
import { Merchant } from '@src/types/Merchant'
import { anyDocsAwaitingApproval } from '@src/data/creditapp-selectors'
import GetCreditContentConsentText from '@src/containers/CreditApplicationPage/components/creditApplicationForm-selectors'
import { ECreditApplicationStatus, EDecision } from '@src/types/Constants'
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase'
import { useTranslation } from 'react-i18next'
import CreditApproved from './CreditApproved'
import CreditConsentDialog from '../../../../components/CreditConsentDialog'
import CreditDeclined from './CreditDeclined'
import CreditManualRevision from './CreditManualRevision'
import CreditDocumentsRequired from './CreditDocumentsRequired'
import StepStatusSection from '../StepStatusSection'
import PrequalNotCompleted from './PrequalNotCompleted'
import ApplicationError from '../applicationError'
import { GetDefaultIconAndMessageFromDecision } from '../../viewCreditApp-selectors'
import { canPollCreditApp, isServerAnalysingCredit } from '../../credit-hooks'

type Props = {
  creditApp: CreditApplication
  merchant: Merchant
  canEdit: boolean
}

const CreditTile = ({ creditApp, merchant, canEdit }: Props) => {
  const { t } = useTranslation()
  const [openConsentDialog, setOpenConsentDialog] = useState(false)
  const [consentChecked, setConsentChecked] = useState(false)

  const [qualify, isQualifying, isError, isSuccess] = useQualify()
  const isAnalysing = isServerAnalysingCredit(creditApp) && canPollCreditApp(creditApp)
  const docsAwaitingApproval = anyDocsAwaitingApproval(creditApp.requiredDocuments)

  const handleCheckboxChange: SwitchBaseProps['onChange'] = (event) => {
    setConsentChecked(event.target.checked)
  }

  const handleConfirm = async () => {
    if (consentChecked) {
      setOpenConsentDialog(false)
      await qualify({ creditApplicationId: creditApp.id, financingProgramId: creditApp.financingProgramId })
    }
  }

  if (creditApp.prequalificationDecision && creditApp.prequalificationDecision.decision !== EDecision.Accept) {
    return (
      <StepStatusSection title={t('viewCreditApplication.creditSectionTitle')}>
        <PrequalNotCompleted />
      </StepStatusSection>
    )
  }
  const icon = GetDefaultIconAndMessageFromDecision(
    creditApp.finalCreditDecision.decision,
    creditApp,
    isAnalysing,
    docsAwaitingApproval,
  )

  return (
    <StepStatusSection
      title={t('viewCreditApplication.creditSectionTitle')}
      iconMessage={icon.iconMessage ? t(`creditApplicationStatus.${icon.iconMessage}`) : null}
      iconType={icon.iconType}
    >
      {isAnalysing && creditApp.status === ECreditApplicationStatus.Active && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {!creditApp.consentHardHit && creditApp.status === ECreditApplicationStatus.Active && !canEdit && (
        <Typography>{t('viewCreditApplication.clientManualRevision')}</Typography>
      )}
      {!creditApp.consentHardHit && creditApp.status === ECreditApplicationStatus.Active && canEdit && (
        <>
          <AsyncActionButton
            onClick={() => setOpenConsentDialog(true)}
            isPending={isQualifying}
            isError={isError}
            isSuccess={isSuccess}
            variant="contained"
          >
            {t('viewCreditApplication.qualify')}
          </AsyncActionButton>
          <CreditConsentDialog
            openConsentDialog={openConsentDialog}
            setOpenConsentDialog={setOpenConsentDialog}
            consentChecked={consentChecked}
            handleCheckboxChange={handleCheckboxChange}
            handleConfirm={handleConfirm}
            dialogContent={<GetCreditContentConsentText financingProgram={creditApp.financingProgramId} />}
            buttonText={t('submission.submit')}
          />
        </>
      )}
      {icon.iconMessage === ECreditApplicationStatus.Error && <ApplicationError />}
      {icon.iconMessage === ECreditApplicationStatus.Cancelled &&
        creditApp.finalCreditDecision.decision !== EDecision.Accept && (
          <Typography variant="body1">{t('viewCreditApplication.creditAppCancelled')}</Typography>
        )}
      {creditApp.finalCreditDecision.decision !== EDecision.Pending && (
        <>
          {creditApp.finalCreditDecision.decision === EDecision.Accept && (
            <CreditApproved creditApp={creditApp} merchant={merchant} />
          )}
          {icon.iconMessage === ECreditApplicationStatus.Declined && (
            <CreditDeclined creditApp={creditApp} canEdit={canEdit} />
          )}
          {icon.iconMessage === ECreditApplicationStatus.DocumentsRequired && (
            <CreditDocumentsRequired creditapp={creditApp} canEdit={canEdit} />
          )}
          {icon.iconMessage === ECreditApplicationStatus.ManualRevision && (
            <CreditManualRevision creditApp={creditApp} canEdit={canEdit} />
          )}
          {icon.iconMessage === ECreditApplicationStatus.AwaitingApproval && (
            <Typography>{t('viewCreditApplication.clientManualRevision')}</Typography>
          )}
        </>
      )}
    </StepStatusSection>
  )
}

export default CreditTile
