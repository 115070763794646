import { useEffect, useState } from 'react'
import { Grid, Divider, Typography, Stack } from '@mui/material'
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SelectComponent from '@src/components/SelectComponent'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import InputTextField from '@src/components/InputTextField'
import { ApplicantExpenses } from '@src/types/CreditApplicationSchema'
import { EHomeStatus } from '@src/types/Constants'
import Fieldset from '@src/components/common/Fieldset'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: UseFormTrigger<any>
  errors?: Merge<FieldError, FieldErrorsImpl<ApplicantExpenses>>
  name: string
  showDetailedForm: boolean
}

const ApplicantExpensesSection = ({ register, watch, setValue, trigger, errors, name, showDetailedForm }: Props) => {
  const { t } = useTranslation()
  const monthlyOtherExpensePayment = String(watch(`${name}.monthlyOtherExpensePayment`))
  const houseFeeType = String(watch(`${name}.homeFeeTypeId`)) as EHomeStatus
  const [isHouseMarketLocked, setIsHouseMarketLocked] = useState<boolean>(false)
  const [isOwner, setIsOwner] = useState<boolean>(false)

  useEffect(() => {
    if (houseFeeType === EHomeStatus.Owner) {
      setIsHouseMarketLocked(false)
      setIsOwner(true)
    } else if (
      houseFeeType === EHomeStatus.WithParents ||
      houseFeeType === EHomeStatus.Renter ||
      houseFeeType === EHomeStatus.ReserveHousing
    ) {
      setValue(`${name}.houseMarketValue`, undefined, { shouldValidate: true })
      setIsHouseMarketLocked(true)
      setIsOwner(false)
    } else if (houseFeeType) {
      setIsHouseMarketLocked(false)
      setIsOwner(false)
    }
  }, [houseFeeType, name, setValue, trigger])

  const isInvalidOtherExpensePayment =
    !monthlyOtherExpensePayment ||
    monthlyOtherExpensePayment.toString().trim() === '' ||
    monthlyOtherExpensePayment.toString().trim() === '0'
  useEffect(() => {
    if (isInvalidOtherExpensePayment) {
      setValue(`${name}.otherExpensePaymentDescription`, '')
    }
  }, [isInvalidOtherExpensePayment, monthlyOtherExpensePayment, name, setValue])

  return (
    <>
      <Divider>
        <Typography variant="subtitle1">
          {t('editCreditApplication.expenses.recurringMonthlyHomeRelatedDisbursements')}
        </Typography>
      </Divider>
      <Fieldset label={t('editCreditApplication.expenses.homeFeesLebel')}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
          <SelectComponent
            valueList={Object.values(EHomeStatus).map((item) => {
              const data: SelectValueListItem = { label: `homeStatus.${item}`, value: item }
              return data
            })}
            label={t('editCreditApplication.expenses.homeFeeTypeId') as string}
            {...register(`${name}.homeFeeTypeId`)}
            error={errors?.homeFeeTypeId}
          />
          {!isOwner && (
            <InputTextField
              id="monthlyHomePayment"
              error={errors?.housingMonthly}
              label={t('editCreditApplication.expenses.monthlyHomePayment')}
              {...register(`${name}.housingMonthly`)}
            />
          )}
          <InputTextField
            id="houseMarketValue"
            disabled={isHouseMarketLocked}
            error={errors?.houseMarketValue}
            label={t('editCreditApplication.expenses.houseMarketValue')}
            {...register(`${name}.houseMarketValue`)}
          />
        </Stack>
      </Fieldset>
      {showDetailedForm && (
        <>
          <Fieldset label={t('editCreditApplication.expenses.others')}>
            <Grid container spacing={1} rowSpacing={2}>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyOwnerInsuranceFee"
                  error={errors?.monthlyOwnerInsuranceFee}
                  label={t('editCreditApplication.expenses.monthlyOwnerInsuranceFee')}
                  {...register(`${name}.monthlyOwnerInsuranceFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyMortgageInsuranceFee"
                  error={errors?.monthlyMortgageInsuranceFee}
                  label={t('editCreditApplication.expenses.monthlyMortgageInsuranceFee')}
                  {...register(`${name}.monthlyMortgageInsuranceFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyMunicipalAndSchoolTaxPayment"
                  error={errors?.monthlyMunicipalAndSchoolTaxPayment}
                  label={t('editCreditApplication.expenses.monthlyMunicipalAndSchoolTaxPayment')}
                  {...register(`${name}.monthlyMunicipalAndSchoolTaxPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyCondominiumFee"
                  error={errors?.monthlyCondominiumFee}
                  label={t('editCreditApplication.expenses.monthlyCondominiumFee')}
                  {...register(`${name}.monthlyCondominiumFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyPublicServiceFee"
                  error={errors?.monthlyPublicServiceFee}
                  label={t('editCreditApplication.expenses.monthlyPublicServiceFee')}
                  {...register(`${name}.monthlyPublicServiceFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyRrspRefund"
                  error={errors?.monthlyRrspRefund}
                  label={t('editCreditApplication.expenses.monthlyRrspRefund')}
                  {...register(`${name}.monthlyRrspRefund`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyOtherHomeFee"
                  error={errors?.monthlyOtherHomeFee}
                  label={t('editCreditApplication.expenses.monthlyOtherHomeFee')}
                  {...register(`${name}.monthlyOtherHomeFee`)}
                />
              </Grid>
            </Grid>
          </Fieldset>
          <Divider>
            <Typography variant="subtitle1">
              {t('editCreditApplication.expenses.monthlyDisbursementsWithCreditAgreement')}
            </Typography>
          </Divider>
          <Fieldset label={t('editCreditApplication.expenses.loanAndCreditLabel')}>
            <Grid container spacing={1} rowSpacing={2}>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyInstallmentLoanPayment"
                  error={errors?.monthlyInstallmentLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyInstallmentLoanPayment')}
                  {...register(`${name}.monthlyInstallmentLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyStudentLoanPayment"
                  error={errors?.monthlyStudentLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyStudentLoanPayment')}
                  {...register(`${name}.monthlyStudentLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyLineOfCreditPayment"
                  error={errors?.monthlyLineOfCreditPayment}
                  label={t('editCreditApplication.expenses.monthlyLineOfCreditPayment')}
                  {...register(`${name}.monthlyLineOfCreditPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyCreditCardsPayment"
                  error={errors?.monthlyCreditCardsPayment}
                  label={t('editCreditApplication.expenses.monthlyCreditCardsPayment')}
                  {...register(`${name}.monthlyCreditCardsPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyPaydayLoanPayment"
                  error={errors?.monthlyPaydayLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyPaydayLoanPayment')}
                  {...register(`${name}.monthlyPaydayLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyPersonalLoanPayment"
                  error={errors?.monthlyPersonalLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyPersonalLoanPayment')}
                  {...register(`${name}.monthlyPersonalLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  id="monthlyOtherLoanPayment"
                  error={errors?.monthlyOtherLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyOtherLoanPayment')}
                  {...register(`${name}.monthlyOtherLoanPayment`)}
                />
              </Grid>
            </Grid>
          </Fieldset>
          <Fieldset label={t('editCreditApplication.expenses.others')}>
            <Grid container spacing={1} rowSpacing={2}>
              <Grid item xs={12} md={4}>
                <InputTextField
                  id="monthlyOtherExpensePayment"
                  error={errors?.monthlyOtherExpensePayment}
                  label={t('editCreditApplication.expenses.monthlyOtherExpensePayment')}
                  {...register(`${name}.monthlyOtherExpensePayment`)}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <InputTextField
                  id="otherExpensePaymentDescription"
                  disabled={isInvalidOtherExpensePayment}
                  error={errors?.otherExpensePaymentDescription}
                  label={t('editCreditApplication.expenses.otherExpensePaymentDescription')}
                  {...register(`${name}.otherExpensePaymentDescription`)}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
          </Fieldset>
        </>
      )}
    </>
  )
}

ApplicantExpensesSection.defaultProps = {
  errors: undefined,
}
export default ApplicantExpensesSection
