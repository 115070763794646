import AddIcon from '@mui/icons-material/Add'
import { Alert, AlertTitle, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { Constants, EApplicantType, EContractStatus, EFinancingProgram } from '@src/types/Constants'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreditApplicationById } from '@src/data/api/credit-api/credit-api'
import { getCanEdit } from '@src/data/creditapp-selectors'
import CreditTile from './components/CreditSection/CreditTile'
import DocumentTable from './components/DocumentSection/DocumentTable'
import PrequalTile from './components/PrequalSection/PrequalTile'
import ApplicantInfoBox from './components/applicantInfoBox'
import LoanInfoBox from './components/loanInfoBox'
import { isServerAnalysingCredit, canPollCreditApp, isServerCreatingContract } from './credit-hooks'
import ContractTile from './components/ContractSection/ContractTile'
import IncomeVerificationTile from './components/IncomeVerificationSection/IncomeVerificationTile'
import FundingTile from './components/FundingSection/FundingTile'
import PageSpinner from '../../components/PageSpinner'

const ViewCreditApplicationPage = () => {
  const { t } = useTranslation()
  const [shouldPoll, setShouldPoll] = useState(false)
  const { financingProgramId, id } = useParams<{ financingProgramId: EFinancingProgram; id: string }>()

  const [creditApplication, isLoadingCreditApp] = useCreditApplicationById(
    {
      creditApplicationId: id!,
      financingProgramId: financingProgramId!,
    },
    shouldPoll,
  )
  const [merchant, isLoadingMerchant] = useMerchantById(creditApplication?.merchantId ?? '')

  const [error, setError] = useState<Error | null>(null)

  const canEdit = getCanEdit(creditApplication?.originSystemId, creditApplication?.editLocked)
  const navigate = useNavigate()

  useEffect(() => {
    if (creditApplication) {
      const should =
        (isServerAnalysingCredit(creditApplication) || isServerCreatingContract(creditApplication)) &&
        canPollCreditApp(creditApplication)
      setShouldPoll(should)
    }
  }, [creditApplication, isLoadingCreditApp])

  return (
    <>
      <PageSpinner isLoading={isLoadingMerchant || isLoadingCreditApp} withBackdrop />
      {error && (
        <Alert
          severity="error"
          sx={{
            width: '100%',
            marginBottom: '2rem',
          }}
        >
          <AlertTitle>{t('common.error')}</AlertTitle>
          {error.message}
        </Alert>
      )}
      {creditApplication && merchant && (
        <>
          <Grid container spacing={2} sx={{ wordWrap: 'break-word', mt: 0 }} columns={12}>
            {creditApplication && merchant?.name && (
              <Grid item xs={4}>
                <LoanInfoBox creditApp={creditApplication} merchant={merchant} canEdit={canEdit} />
              </Grid>
            )}
            {creditApplication.applicant && (
              <ApplicantInfoBox
                financingProgramId={financingProgramId!}
                applicant={creditApplication.applicant}
                applicantTitle={t('viewCreditApplication.applicant')}
                applicantType={EApplicantType.Applicant}
                creditApplicationId={id!}
                disabled={creditApplication?.contract?.status === EContractStatus.completed || !canEdit}
              />
            )}
            {creditApplication.coApplicant && (
              <ApplicantInfoBox
                financingProgramId={financingProgramId!}
                applicant={creditApplication?.coApplicant}
                applicantTitle={t('viewCreditApplication.coApplicant')}
                applicantType={EApplicantType.Coapplicant}
                creditApplicationId={id!}
                disabled={creditApplication?.contract?.status === EContractStatus.completed || !canEdit}
              />
            )}
            {!creditApplication.coApplicant &&
              creditApplication?.contract?.status !== EContractStatus.completed &&
              canEdit && (
                <Paper
                  component={Grid}
                  item
                  xs
                  sx={{
                    borderRadius: 2,
                    p: 1,
                    m: 1,
                  }}
                >
                  <Stack direction="column" justifyContent="center" alignItems="center" height="100%" spacing={1}>
                    <IconButton
                      onClick={() => {
                        navigate(`/credits/${creditApplication.financingProgramId}/${id}/coapplicant`)
                      }}
                    >
                      <AddIcon style={{ fontSize: '3rem' }} />
                    </IconButton>
                    {t('viewCreditApplication.addCoapp')}
                  </Stack>
                </Paper>
              )}
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h5" component="div">
                {t('viewCreditApplication.applicationProcessTracking')}
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                useFlexGap
                sx={{
                  scrollbarWidth: 'thin',
                  overflowY: 'auto',
                  pb: 2,
                }}
              >
                {Constants.financingProgramConfigs[financingProgramId!].displayPrequalify && (
                  <PrequalTile creditApp={creditApplication} />
                )}
                <CreditTile creditApp={creditApplication} merchant={merchant} canEdit={canEdit} />
                {Constants.financingProgramConfigs[financingProgramId!].displayIncomeVerification && (
                  <>
                    <IncomeVerificationTile
                      financingProgramId={financingProgramId!}
                      applicant={creditApplication?.applicant}
                      requiredDocuments={creditApplication?.requiredDocuments}
                      creditDecision={creditApplication?.finalCreditDecision.decision}
                      applicationStatus={creditApplication?.status}
                      creditApplicationId={creditApplication?.id}
                      merchant={merchant}
                      hasCoApplicant={creditApplication.coApplicant !== null}
                      canEdit={canEdit}
                    />
                    {creditApplication?.coApplicant && (
                      <IncomeVerificationTile
                        financingProgramId={financingProgramId!}
                        applicant={creditApplication?.coApplicant}
                        requiredDocuments={creditApplication?.requiredDocuments}
                        creditDecision={creditApplication?.finalCreditDecision.decision}
                        applicationStatus={creditApplication?.status}
                        creditApplicationId={creditApplication?.id}
                        merchant={merchant}
                        hasCoApplicant
                        canEdit={canEdit}
                      />
                    )}
                  </>
                )}
                <ContractTile creditApp={creditApplication} isPolling={shouldPoll} canEdit={canEdit} />
                <FundingTile creditApp={creditApplication} />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <DocumentTable
              financingProgramId={financingProgramId!}
              requiredDocuments={creditApplication.requiredDocuments}
              creditApplicationId={id!}
              setError={setError}
              canEdit={canEdit}
            />
          </Grid>
        </>
      )}
    </>
  )
}

export default ViewCreditApplicationPage
